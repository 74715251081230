import { useBannerOrBlocker, useLegalLinks } from "..";
function joinWithAndSeparator(arr, andSeparator) {
  const useArr = arr.filter(Boolean);
  if (useArr.length > 1) {
    useArr.splice(useArr.length - 1, 0, "{{andSeparator}}");
  }
  return useArr.join(", ").replace(/,\s+{{andSeparator}},\s+/g, andSeparator);
}

/**
 * @see https://gist.github.com/unruthless/413930
 * @see https://stackoverflow.com/a/23630325/5506547
 */
const supWithLineHeightFix = '<sup style="vertical-align:top;line-height:100%;position:initial;">';
const createSuperScriptTag = function (sup) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  const useSup = sup.filter(Boolean);
  return useSup.length === 0 ? prefix : `${prefix}${supWithLineHeightFix}${useSup.join(",")}</sup>`;
};
function useCommonTeachings(_ref) {
  let {
    services,
    disableListServicesNotice
  } = _ref;
  const {
    blocker,
    groups,
    isEPrivacyUSA,
    isAgeNotice,
    isListServicesNotice,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    texts: {
      description,
      ePrivacyUSA,
      dataProcessingInUnsafeCountries,
      ageNoticeBanner,
      listServicesNotice,
      listServicesLegitimateInterestNotice,
      consentForwardingExternalHosts: consentForwardingExternalHostsText
    },
    tcf,
    consentForwardingExternalHosts,
    individualPrivacyOpen,
    individualTexts: {
      description: individualDescription
    },
    essentialGroup,
    designVersion,
    i18n: {
      andSeparator,
      tcf: tcfBannerI18n
    },
    keepVariablesInTexts
  } = useBannerOrBlocker();
  const {
    linkPrivacyPolicy
  } = useLegalLinks();
  const allServices = groups.map((group, groupIdx) => group.items.map(service => {
    const {
      legalBasis
    } = service;
    const isEssentialGrouped = group.slug === essentialGroup;

    // Essential-grouped services implicitely set legitimate interest as legal basis
    const useLegalBasis = isEssentialGrouped && designVersion >= 4 && legalBasis === "consent" ? "legitimate-interest" : legalBasis;
    return {
      service,
      legalBasis: useLegalBasis,
      isEssentialGrouped,
      group,
      groupIdx
    };
  })).flat();

  // Description text (combination of configured text and Consent Forwarding)
  let consentForwardingExternalHostsFinalText = "";
  if (consentForwardingExternalHosts && !keepVariablesInTexts) {
    consentForwardingExternalHostsFinalText = consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", "));
  }
  let useDescription = [blocker ? [blocker.description, designVersion > 2 ? description : false].filter(Boolean).join("\n\n") : individualPrivacyOpen ? individualDescription : description, consentForwardingExternalHostsFinalText].filter(Boolean).join(" ");
  if (!keepVariablesInTexts) {
    // Place policy link into the text https://regex101.com/r/ayeFVy/1
    useDescription = useDescription.replace(/{{privacyPolicy}}(.*){{\/privacyPolicy}}/gi, linkPrivacyPolicy ? `<a href="${linkPrivacyPolicy.url}" style="text-decoration:underline;color:inherit;" onmouseenter="this.style.textDecoration='none'" onmouseleave="this.style.textDecoration='underline'" target="_blank">$1</a>` : "$1");
  }

  // Teaching: Data processing in unsafe countries (formerly USA ePrivacy)
  const servicesProcessingUnsafeCountries = services.filter(_ref2 => {
    let {
      ePrivacyUSA,
      dataProcessingInCountries
    } = _ref2;
    return (
      // Legacy ePrivacyUSA flag
      ePrivacyUSA || dataProcessingInCountries.some(c => dataProcessingInUnsafeCountriesSafeCountries.indexOf(c) === -1)
    );
  });
  const tcfVendorsProcessingUnsafeCountries = process.env.IS_TCF && tcf ? Object.values(tcf.gvl.vendors).filter(vendor => {
    const {
      ePrivacyUSA,
      dataProcessingInCountries
    } = tcf.original.vendorConfigurations[vendor.id];

    // Legacy ePrivacyUSA flag
    if (typeof ePrivacyUSA === "number") {
      const additionalInformation = vendor.additionalInformation;
      return additionalInformation?.internationalTransfers || ePrivacyUSA === 1;
    } else {
      return dataProcessingInCountries.some(c => dataProcessingInUnsafeCountriesSafeCountries.indexOf(c) === -1);
    }
  }) : [];
  let useDataProcessingInUnsafeCountries = (servicesProcessingUnsafeCountries.length > 0 || tcfVendorsProcessingUnsafeCountries.length > 0) && ((isEPrivacyUSA ? ePrivacyUSA : "") || (isDataProcessingInUnsafeCountries ? dataProcessingInUnsafeCountries : ""));

  // Teaching: Age notice
  const useAgeNotice = isAgeNotice ? ageNoticeBanner : "";

  // Teaching: List services notice
  let useListServicesNotice = "";
  if (isListServicesNotice && !disableListServicesNotice) {
    const listServicesNoticeLower = listServicesNotice.toLowerCase();
    const hasBothPlaceholders = listServicesNoticeLower.indexOf("{{services}}") > -1 && listServicesNoticeLower.indexOf("{{servicegroups}}") > -1;
    const listServicesNames = joinWithAndSeparator(allServices.map(_ref3 => {
      let {
        service,
        legalBasis,
        isEssentialGrouped,
        groupIdx
      } = _ref3;
      const {
        name
      } = service;
      if (
      // Never show "Real Cookie Banner" as service
      legalBasis === "legal-requirement" ||
      // For backwards-compatibility stick to the old behavior and not show essential services
      designVersion < 4 && isEssentialGrouped ||
      // When a legitimate-interest notice is given, exclude them here and show them in a separate paragraph
      listServicesLegitimateInterestNotice && legalBasis !== "consent") {
        return undefined;
      }
      return createSuperScriptTag([hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && servicesProcessingUnsafeCountries.indexOf(service) > -1 ? "U" : ""], name);
    }), andSeparator);
    const listServicesLegitimateInterestNames = listServicesLegitimateInterestNotice ? joinWithAndSeparator(allServices.map(_ref4 => {
      let {
        service,
        legalBasis,
        groupIdx
      } = _ref4;
      const {
        name
      } = service;
      if (legalBasis !== "legitimate-interest") {
        return undefined;
      }
      return createSuperScriptTag([hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && servicesProcessingUnsafeCountries.indexOf(service) > -1 ? "U" : ""], name);
    }), andSeparator) : "";
    if (listServicesNames) {
      const checkForGroupInComposedString = `${listServicesNames}${listServicesLegitimateInterestNames}`;
      const listServiceGroupsNames = joinWithAndSeparator(groups.map((_ref5, groupIdx) => {
        let {
          name
        } = _ref5;
        const groupSuperScript = groupIdx + 1;

        // Is this service used in the list above?
        if (checkForGroupInComposedString.indexOf(`>${groupSuperScript}`) === -1) {
          return "";
        }
        return createSuperScriptTag([hasBothPlaceholders ? groupSuperScript : ""], name);
      }), andSeparator);
      useListServicesNotice = `<span>${listServicesNotice}</span>`;
      if (!keepVariablesInTexts) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/gi, listServicesNames)
        // Place service group list into the text
        .replace(/{{serviceGroups}}/gi, listServiceGroupsNames);
      }
      if (useDataProcessingInUnsafeCountries) {
        useDataProcessingInUnsafeCountries += createSuperScriptTag(["U"]);
      }
    }

    // Show a separate paragraph about services with legitimate-interest as legal basis (designVersion >= 4)
    if (listServicesLegitimateInterestNames) {
      useListServicesNotice += ` <span>${listServicesLegitimateInterestNotice}</span>`;
      if (!keepVariablesInTexts) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/gi, listServicesLegitimateInterestNames);
      }
    }
    useListServicesNotice += tcf && !individualPrivacyOpen ? ` <span>${tcfBannerI18n.listOfServicesAppendix}</span>` : "";
  }
  return {
    description: useDescription,
    teachings: [useDataProcessingInUnsafeCountries, useAgeNotice, useListServicesNotice].filter(Boolean)
  };
}
export { useCommonTeachings };